module.exports = [{
      plugin: require('../node_modules/gatsby-background-image-es5/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Colleen Dunn","short_name":"Colleen Dunn","start_url":"/","background_color":"#8B5CF6","theme_color":"#8B5CF6","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d3725c3e5b99d64506c059238b70bd45"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
